.edgebutton {
    width: 20px;
    height: 20px;
    background: #eee;
    border: 1px solid #fff;
    cursor: pointer;
    border-radius: 50%;
    font-size: 12px;
    line-height: 1;
}

.edgebutton:hover {
    background: #5e35b1;
    color: #eee;
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
}

.reactflow-parent-wrapper {
    display: flex;
    flex-grow: 1;
    height: 100%;
}

.reactflow-parent-wrapper .reactflow-wrapper {
    flex-grow: 1;
    height: 100%;
}

.edgebutton-handle {
  height: 12px;
  width: 12px;
  background: #939ea8;
  z-index: 5;
  /*top: position*/
}

.wrapper {
  flex-grow: 1;
  height: 100%;
}
.flow-custom-controls{
  position: fixed;
  top: 33%;
  right: 10px;
  transform: translateY(-50%);
  margin-bottom: 5px;
   display: flex;
  flex-direction: column;
  z-index: 10;
   padding-right: 10px;
  margin-right: 10px;
}
.flow-controls {
  position: fixed;
  top: 40%;
  right: 10px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  z-index: 10;
  padding-right: 10px;
  margin-right: 10px;
}

